exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-commercial-js": () => import("./../../../src/pages/commercial.js" /* webpackChunkName: "component---src-pages-commercial-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industrial-js": () => import("./../../../src/pages/industrial.js" /* webpackChunkName: "component---src-pages-industrial-js" */),
  "component---src-pages-residential-js": () => import("./../../../src/pages/residential.js" /* webpackChunkName: "component---src-pages-residential-js" */)
}

